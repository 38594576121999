<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader header-text="Plugins">
      <template #subheader>
        Manage your plugins configured for this account
      </template>
    </PageHeader>

    <v-container
      v-if="accountPlugins.length > 0"
      class="pa-0 d-flex flex-column align-center d-sm-block"
      :class="{ 'mobile-view': $vuetify.breakpoint.xsOnly }"
      fluid
    >
      <div class="accounts-box-head mt-10 mb-3">
        Active
      </div>
      <div class="d-flex flex-wrap justify-start">
        <plugin-card
          v-for="account_plugin in accountPlugins"
          :key="account_plugin.id"
          :plugin="account_plugin.plugin"
          :installed="true"
          :is-activated="account_plugin.isActive"
          @install="configure_plugin(account_plugin, true)"
          @activate="activatePlugin(account_plugin.id)"
        />
      </div>
    </v-container>

    <div class="accounts-box-head mt-10 mb-3">
      All Plugins
    </div>
    <SearchFieldRowWithPagination
      search-field-placeholder="Search for plugin"
      no-pagination
      @search="(search_string) => on_search(search_string)"
    />

    <v-container
      class="pa-0 d-flex flex-column align-center d-sm-block"
      :class="{ 'mobile-view': $vuetify.breakpoint.xsOnly }"
      fluid
    >
      <div class="d-flex flex-wrap justify-start">
        <plugin-card
          v-for="plugin in (only_inactive_plugins ? inactive_plugins : plugins)"
          :key="plugin.id"
          :plugin="plugin"
          @install="configure_plugin(plugin, false)"
        />
      </div>
    </v-container>

    <v-dialog v-model="dialog" scrollable max-width="520px" max-height="90px">
      <ConfigurePluginDialog
        :plugin="selected_plugin"
        @dismiss="dialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PluginCard from "@/views/Plugins/components/PluginCard";
import ConfigurePluginDialog from "@/views/Plugins/components/ConfigurePluginDialog";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import PageHeader from "@/sharedComponents/PageHeader";

export default {
  name: "PluginsView",
  metaInfo: {
    title: 'Plugins/Integrations'
  },
  components: {
    PluginCard,
    SearchFieldRowWithPagination,
    PageHeader,
    ConfigurePluginDialog,
  },
  data() {
    return {
      only_inactive_plugins: true,
      dialog: false,
      selected_plugin: null,
      plugins: [],
      accountPlugins: [],
    };
  },
  computed: {
    inactive_plugins() {
      return this.plugins.filter((plugin) => {
        return !this.accountPlugins
          .find(accountPlugin =>
            accountPlugin.plugin.componentName === plugin.componentName
          );
      });
    },
  },
  async created() {
    await this.get_available_plugins();
    await this.get_account_plugins();
  },
  methods: {
    configure_plugin(plugin, installed) {
      let componentName = plugin.componentName;

      // pass plugin id
      if (installed) {
        componentName = plugin.plugin.componentName;
      }

      return this.$router.push({
        name: componentName,
        params: { id: plugin.id },
        query: { installed }
      });
    },

    on_search(search_string) {
      this.get_available_plugins({ name: search_string });
    },

    async get_available_plugins(params) {
      const resp = await this.$rest.plugins.get_plugins({
        ...params,
        ignorePagination: true,
        sort: ['name:asc']
      });
      this.plugins = resp.data.items;
    },
    async get_account_plugins(params) {
      const resp = await this.$rest.plugins.get_account_plugins({
        ...params,
        ignorePagination: true,
      });
      this.accountPlugins = resp.data.items;
      this.accountPlugins.sort((a, b) => {
        const aName = a.plugin.name;
        const bName = b.plugin.name;

        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }

        return 0;
      });
    },
    async activatePlugin(pluginId) {
      let response = await this.$rest.plugins.get_resource(pluginId, {
        includeCredentials: true,
      });
      if (!response.data) {
        return;
      }

      const plugin = {...response.data};

      response = await this.$rest.plugins.put_resource(pluginId, {
        ...plugin,
        plugin: plugin.plugin.id,
        isActive: true,
      });
      if (response.data) {
        this.accountPlugins = Array.from(this.accountPlugins).map(aPlugin => {
          return aPlugin.id === response.data.id ? response.data : aPlugin;
        });

        const selectedAccount = this.$store.getters["user/account"];
        this.$store.commit("user/update_selected_account", {
          ...selectedAccount,
          activePlugins: [...selectedAccount.activePlugins, response.data],
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active-accounts-checkbox {
  .v-input__control {
    .v-messages {
      display: none;
    }
  }
}
.mobile-view {
  > div {
    width: 265px;
  }
}
</style>
